// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-blog-grid-jsx": () => import("./../../../src/pages/blog-grid.jsx" /* webpackChunkName: "component---src-pages-blog-grid-jsx" */),
  "component---src-pages-blog-left-sidebar-jsx": () => import("./../../../src/pages/blog-left-sidebar.jsx" /* webpackChunkName: "component---src-pages-blog-left-sidebar-jsx" */),
  "component---src-pages-blog-right-sidebar-jsx": () => import("./../../../src/pages/blog-right-sidebar.jsx" /* webpackChunkName: "component---src-pages-blog-right-sidebar-jsx" */),
  "component---src-pages-case-studies-jsx": () => import("./../../../src/pages/case-studies.jsx" /* webpackChunkName: "component---src-pages-case-studies-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-templates-case-studies-details-index-jsx": () => import("./../../../src/templates/case-studies-details/index.jsx" /* webpackChunkName: "component---src-templates-case-studies-details-index-jsx" */),
  "component---src-templates-categories-post-index-jsx": () => import("./../../../src/templates/categories-post/index.jsx" /* webpackChunkName: "component---src-templates-categories-post-index-jsx" */),
  "component---src-templates-services-details-index-jsx": () => import("./../../../src/templates/services-details/index.jsx" /* webpackChunkName: "component---src-templates-services-details-index-jsx" */),
  "component---src-templates-single-post-index-jsx": () => import("./../../../src/templates/single-post/index.jsx" /* webpackChunkName: "component---src-templates-single-post-index-jsx" */),
  "component---src-templates-tag-post-index-jsx": () => import("./../../../src/templates/tag-post/index.jsx" /* webpackChunkName: "component---src-templates-tag-post-index-jsx" */)
}

